import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2870615501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "activityfeed"
    }}>{`ActivityFeed`}</h1>
    <h2 {...{
      "id": "types"
    }}>{`Types`}</h2>
    <h3 {...{
      "id": "activityfeeddatasource"
    }}>{`ActivityFeedDataSource`}</h3>
    <p>{`ActivityFeedDataSource{}`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`fn -> ReactApiFunction()`}</inlineCode>{` = (required) API function from @kineticdata/react`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`params -> (prevParams, prevResults, options) => any`}</inlineCode>{` = (required) Function that will build the params that will be passed to the fn api function.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`transform -> (respose: HttpResponse) => { data[] }`}</inlineCode>{` = (required) Function that will transform the results of the api call to a consistent structure that will be provided to the render function of the feed, `}<inlineCode parentName="li">{`data`}</inlineCode>{` represents the list of records to be displayed.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`joinBy -> string|(any) => string`}</inlineCode>{` = An override for this source to the joinBy prop passed to the feed.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`component -> ReactElement()`}</inlineCode>{` (required) Function that will render a record from this source. Should return JSX.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`data -> object[]`}</inlineCode>{` = Static array of objects to use as the data instead of fetching data.`}</li>
    </ul>
    <p><strong parentName="p">{`Note:`}</strong>{` If data is provided, fn, params, and transform are not required, and will not be used.`}</p>
    <h3 {...{
      "id": "activityfeedrenderfunction"
    }}>{`ActivityFeedRenderFunction`}</h3>
    <p><inlineCode parentName="p">{`children(renderOptions)`}</inlineCode></p>
    <ul>
      <li parentName="ul">{`renderOptions`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`reset -> () => null`}</inlineCode>{` = Re-initializes the feed and re-fetches the data. This function must be called if you change the props passed to the feed and want the feed to recognize the changes (the `}<inlineCode parentName="li">{`pageSize`}</inlineCode>{` prop is the only exception; see `}<inlineCode parentName="li">{`resetPaging`}</inlineCode>{` below).`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`loading -> boolean`}</inlineCode>{` = If true, the feed is fetching data from the sources.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`errors -> object|null`}</inlineCode>{` = A named map of errors for each source. Null if there are no errors.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`data -> object[]`}</inlineCode>{` = Array of data to be shown on the current page.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`dataElements -> ReactElement[]`}</inlineCode>{` = Array of elements built from the data to be shown on the current page, generated using the `}<inlineCode parentName="li">{`component`}</inlineCode>{` function from the appropriate `}<inlineCode parentName="li">{`dataSource`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`options -> object`}</inlineCode>{` = The options object passed into the feed.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`startIndex -> number`}</inlineCode>{` = The index of the first record being shown on this page (starts at 1).`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`endIndex -> number`}</inlineCode>{` = The index of the last record being shown on this page.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`count -> string|number`}</inlineCode>{` = The running total of retrieved records from all data sources. Ends in a plus sign if there are more records to retrieve. Only provided if showCount prop is truthy.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`hasPreviousPage -> boolean`}</inlineCode>{` = If true, there is a previous page.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`getPreviousPage -> () => null`}</inlineCode>{` = Function that will load the data for the previous page.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`hasNextPage -> boolean`}</inlineCode>{` = If true, there is a next page.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`getNextPage -> () => null`}</inlineCode>{` = Function that will load the data for the next page.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`resetPaging -> () => null`}</inlineCode>{` = Function that will reset the paging for the data back to page one. If the `}<inlineCode parentName="li">{`pageSize`}</inlineCode>{` prop provided to the feed was changed, this function will capture that change.`}</li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`feedKey -> string`}</inlineCode>{` = A unique identifier for this instance of the ActivityFeed. Used for mounting, unmounting, and refetching the ActivityFeed from outside the component. When omitted, the ActivityFeed will mount and unmount automatically. When provided, you will need to call mountActivityFeed and unmountActivityFeed to initialize and destroy the feed.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`uncontrolled -> boolean`}</inlineCode>{` = If true, the ActivityFeed will automatically mount and unmount even if feedKey is provided.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`pageSize -> number`}</inlineCode>{` = The number of results that should be displayed on each page.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`joinBy -> string|function`}</inlineCode>{` = The number of results that should be displayed on each page.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`joinByComparator -> function`}</inlineCode>{` = Sort function used to sort the next joinBy values from each source to determine which value should be added to the joined list next.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`joinByDirection -> string`}</inlineCode>{` = Direction (ASC or DESC) in which the next joinBy values should be sorted in when joinByComparator is not provided.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`dataSources -> { key: ActivityFeedDataSource{} }`}</inlineCode>{` | A named map of objects that define the `}<a parentName="li" {...{
          "href": "#activityfeeddatasource"
        }}>{`data sources`}</a>{` from that should be fetched. |`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`showCount -> bool|{ key: number }`}</inlineCode>{` = Enables keeping track of the total count of records retrieved. If a map is provided, the key should match the keys of the dataSources map, with the value being tht total count of records in the dataSource. Will display the count in the default pagination component.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`options -> object`}</inlineCode>{` = An object of options passed to each dataSource's params function. Changes to the options will reset the ActivityFeed.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`children ->	function`}</inlineCode>{` = `}<a parentName="li" {...{
          "href": "#activityfeedrenderfunction"
        }}>{`Render function`}</a>{` which will be passed the data for the current page and actions for getting previous and next pages, and is expected to render the content. If not provided, a default renderer will be used.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`contentProps	-> object`}</inlineCode>{` = An object of props to be passed into the default renderer if children is not provided. Currently accepts hidePaging to hide the pagination bar, and emptyMessage to define what appears when there are no results (emptyMessage can be a string, an object with heading, title, and/or message properties, or a function that takes the options passed into the activity feed and returns an object with the aforementioned properties).`}</li>
    </ul>
    <h2 {...{
      "id": "helper-functions"
    }}>{`Helper Functions`}</h2>
    <p>{`The following functions are available as imports from `}<inlineCode parentName="p">{`@kineticdata/bundle-common`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`mountActivityFeed( feedKey: string )`}</inlineCode>{`
This will create the ActivityFeed with the given feedKey in state, allowing the feed to be rendered. If you try to render the ActivityFeed component without mounting it first, nothing will render.`}</p>
    <p><inlineCode parentName="p">{`unmountActivityFeed( feedKey: string )`}</inlineCode>{`
This will remove the ActivityFeed with the given feedKey from state.`}</p>
    <p><inlineCode parentName="p">{`refetchActivityFeed( feedKey: string, preservePage: boolean )`}</inlineCode>{`
This will refetch the data for the ActivityFeed with the given feedKey (if it is mounted). Passing preservePage as true will keep the same page of data rendered. Otherwise the data will reset to page 1.`}</p>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <h3 {...{
      "id": "simple-feed"
    }}>{`Simple Feed`}</h3>
    <p>{`Simple ActivityFeed using default content renderer.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<ActivityFeed
  feedKey="sample-feed"
  uncontrolled={true}
  pageSize={10}
  joinByDirection="DESC"
  joinBy="createdAt"
  dataSources={{
    submissions: {
      fn: searchSubmissions,
      params: (prevParams, prevResult, options) => {
        if (prevParams && prevResult) {
          if (prevResult.nextPageToken) {
            return { ...prevParams, pageToken: prevResult.nextPageToken };
          } else {
            return null;
          }
        } else {
          return {
            kapp: 'services',
            limit: 10,
            search: new SubmissionSearch(true)
              .include('details,values').index('createdAt').build(),
          };
        }
      },
      transform: result => ({
        data: result.submissions.map(s => ({
          createdAt: s.createdAt,
          value: s.values['Value'],
        })),
        nextPageToken: result.nextPageToken,
      }),
      component: record => (
        <div className="card" key={record.createdAt}>
          <div><strong>{record.value}</strong></div>
          <small>{record.createdAt}</small>
        </div>
      ),
    },
  }}
  contentProps={{
    hidePaging: false,
    emptyMessage: {
      title: 'No requests found',
      message: "Once you fill out a form, you will see it's status here.",
    },
  }}
/>
`}</code></pre>
    <h3 {...{
      "id": "custom-content-rendering"
    }}>{`Custom Content Rendering`}</h3>
    <p>{`ActivityFeed using custom content renderer and manual mounting and unmounting.`}</p>
    <p>{`You will need to call `}<inlineCode parentName="p">{`mountActivityFeed('complex-feed')`}</inlineCode>{` in order for the `}<inlineCode parentName="p">{`ActivityFeed`}</inlineCode>{` to render.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<ActivityFeed
  feedKey="complex-feed"
  pageSize={10}
  joinByDirection="DESC"
  joinBy="createdAt"
  dataSources={{
    submissions: {
      fn: searchSubmissions,
      params: (prevParams, prevResult, options) => {
        if (prevParams && prevResult) {
          if (prevResult.nextPageToken) {
            return { ...prevParams, pageToken: prevResult.nextPageToken };
          } else {
            return null;
          }
        } else {
          return {
            kapp: 'services',
            limit: 10,
            search: new SubmissionSearch(true)
              .include('details,values').index('createdAt').build(),
          };
        }
      },
      transform: result => ({
        data: result.submissions,
        nextPageToken: result.nextPageToken,
      }),
      component: record => (
        <tr key={record.createdAt}>
          <td><strong>{record.values['Field Value'}</strong></td>
          <td>{record.createdAt}</td>
        </tr>
      ),
    },
    incidents: {
      fn: fetchBridgedResource,
      params: (prevParams, prevResult, options) => {
        if (prevParams && prevResult) {
          return {
            ...prevParams,
            metadata: {
              ...prevParams.metadata,
              offset: prevParams.metadata.offset + prevParams.metadata.pageSize,
            },
          };
        } else {
          return {
            kappSlug: 'services',
            formSlug: 'shared-resources',
            bridgedResourceName: 'Remedy Incidents by Username',
            metadata: { pageSize: 11, offset: 0, order: 'Created At:DESC' },
            values: { Username: 'test_user' },
          };
        }
      },
      transform: result => ({
        data: result.records,
      }),
      component: record => (
        <tr key={record['Created At']}>
          <td><a href="/">{record['Attribute Value'}</a></td>
          <td>{record['Created At']}</td>
        </tr>
      ),
    },
  }}
>
  {ActivityFeedContent}
</ActivityFeed>

const ActivityFeedContent = ({
  reset,
  loading,
  errors,
  data,
  dataElements,
  options,
  startIndex,
  endIndex,
  hasPreviousPage,
  getPreviousPage,
  hasNextPage,
  getNextPage,
  resetPaging,
}) => (
  <Fragment>
    <table className="table table-sm table-striped">
      <thead>
      <tr>
        <th>Value</th>
        <th>Date</th>
      </tr>
      </thead>
      <tbody>
      {errors && (
        <tr>
          <td colSpan="2" className="text-danger">
            {Map(errors).map((error, key) => (
              <p key={key}>
                <span className="d-block">Failed to retrieve {key}</span>
                <small className="text-muted">{error.message}</small>
              </p>
            )).toList()}
          </td>
        </tr>
      )}
      {dataElements}
      {!data && loading && (
        <tr>
          <td colSpan="2" className="text-info text-center">Loading...</td>
        </tr>
      )}
      </tbody>
    </table>
    {data && (
      <div className="pagination-bar">
        <button
          className="btn btn-icon"
          onClick={getPreviousPage}
          disabled={loading || !hasPreviousPage}
          title="Previous Page"
        >
          <span className="fa fa-fw fa-caret-left" />
        </button>
        <small>
          {loading ? (
            <span className="fa fa-spinner fa-spin" />
          ) : (
            <strong>{\`\${startIndex}-\${endIndex}\`}</strong>
          )}
        </small>
        <button
          className="btn btn-icon"
          onClick={getNextPage}
          disabled={loading || !hasNextPage}
          title="Next Page"
        >
          <span className="fa fa-fw fa-caret-right" />
        </button>
      </div>
    )}
  </Fragment>
);
`}</code></pre>
    <h2 {...{
      "id": "activityfeedmountwrapper"
    }}>{`ActivityFeed.MountWrapper`}</h2>
    <p>{`A wrapper component that will handle the mounting and unmounting of an activity feed. Meant to be used inside a Router component to wrap multiple routes, allowing an ActivityFeed to remain in state when the user navigates within these routes (e.g. wrapping a list route and a details route will allow a user to go from the list ot the details and then back, without the ActivityFeed resetting).`}</p>
    <h3 {...{
      "id": "props-1"
    }}>{`Props`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`feedKey	-> string`}</inlineCode>{` = A unique identifier for this instance of the ActivityFeed. You will need to pass this same feedKey to the ActivityFeed that will be rendered within this wrapper.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`children -> ReactElement()`}</inlineCode>{` = Any content passed as children will be rendered by the MountWrapper.`}</li>
    </ul>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Router>
  {/* ...other components/routes... */}
  <ActivityFeed.MountWrapper feedKey="sample-feed" path="feed">
    <FeedList feedKey="sample-feed" default />
    <FeedDetail feedKey="sample-feed" path=":id" />
  </ActivityFeed.MountWrapper>
</Router>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      